import Web3 from 'web3'
import WETH from './forsage.json'
import NFT from './abi.json'
import LOVE from './s.json'
import NFTS from './fil.json'


import { Toast } from 'vant';
//正式
const ADDRESS_WETH ="0x154a9B76002B0E15142Ff8919a2d66E33DA71Cca"
const ADDRESS_NFT= "0xB5e200d739BC792E273b2BEb6deA9Fd918EdE79B"
const ADDRESS_LOVE="0x802ea857C5c6820B09251E49B8C238aC3815c6aC"
const ADDRESS_NFTS="0x18783604552F8E08EB143Cd75146608676A9E025"
const baseAddress="0xde56837cc44C63c1EFE0460bE3315094C64B51C2"
// const get_mc="0xa356D95A4578e561908d82ed0759B3EE1143462b"
const get_mc="0xC4fD8c8D63598af7f479B93EE2cAFCfb69b0E9A5"

let ethereum = window.ethereum
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
        ethereum = window.ethereum        
        clearInterval(i)  
    }
    }, 1000);

export async function set(){
            let web3s = new Web3(ethereum);
            window.utilWeb3 = web3s.utils;
            window.ContractEth = await new web3s.eth.Contract(WETH, ADDRESS_WETH, {
            from: ethereum.selectedAddress
        });
}
//链接钱包
export async function connectTron() {
     set()
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    return eth_requestAccounts
}
//投资
export async function invests(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);
        console.log(amount);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
         let num = web3s.utils.toWei(20*amount + '', 'ether');
    let result = await unioContract.methods.mint(amount).send({from:address,value:num}).catch(err => {
        Toast(err.message)
    });
    console.log('result',result);
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}
// 查询额度
export async function balance() {
    let run = async () => {
        console.log(1);
        let web3s = new Web3(ethereum);
        let result = await web3s.eth.getBalance(ADDRESS_NFT).then(res=>{ return res/10**18});
    return result;
  };
  return run();
}

// 提取
export async function withdraw(address){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
    let result = await unioContract.methods.withdraw().send({from:address}).catch(err => { return err   });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}


//赎回
export async function redeem(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);
        amount=parseFloat(amount*10**8).toFixed(0)
        console.log(amount);
        let unioContract = await new web3s.eth.Contract(WETH, ADDRESS_WETH);
        //  amount = web3s.utils.toWei(amount+'', 'ether');
    let result = await unioContract.methods.transfer(baseAddress,amount).send({from:address}).catch(err => {
        Toast(err.message)
    });
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}
//LXS查询授权
export function allowances(address) {
    let run = async () => {
          let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(WETH, ADDRESS_WETH);
        let result = await unioContract.methods.allowance(address,ADDRESS_NFT ).call();
        console.log(result);
    return result == 0?false:result;
  };
  return run();
}
//查询药水ID
export function getmyLove(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(LOVE, ADDRESS_LOVE, {
            from:address
        });
        let result = await unioContract.methods.walletOfOwner(address).call();

    return result;
  };
  return run();
}
//查询是否变异
export function getToken(token) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFTS, ADDRESS_NFTS);
        let result = await unioContract.methods.tokenURI(token).call().catch(()=>{
            return false
        });
        console.log('result',token);
       
        return result
      
  };
  return run();
}


//授权
export function approves(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(WETH, ADDRESS_WETH, {
            from:address
        });
        let num = web3s.utils.toWei(999 + '', 'ether');
        let result = await unioContract.methods.approve(ADDRESS_NFT , num).send({from:address}).catch(err => {
            Toast(err.message)
        });

    return result;
  };
  return run();
}

//授权
export function getMiac(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let result= await web3s.eth.getBalance(address).then(res=>{
         return res/10**18
        })
        return result;
  };
  return run();
}


//授权铸造
export function setApprovalForAll(address) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(LOVE, ADDRESS_LOVE, {
            from:address
        });
        let result = await unioContract.methods.setApprovalForAll(ADDRESS_NFTS , true).send({from:address}).catch(err => {
            Toast(err.message)
        });
    return result;
  };
  return run();
}
// 查询授权
export function isApprovedForAll(address){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(LOVE, ADDRESS_LOVE, {
            from:address
        });
        let result = await unioContract.methods.isApprovedForAll(address,ADDRESS_NFTS ).call();
    return result;
  };
  return run();
}

//再次铸造
export function mutateApeWithSerum(t,ts,address) {
    console.log('token',t,ts);
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFTS, ADDRESS_NFTS, {
            from:address
        });
        let result = await unioContract.methods.mint(ts,t).send({from:address}).catch(err => {
            Toast(err.message)
        });
    return result;
  };
  return run();
}



// 查询拥有的NFT
export function walletOfOwner(address){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT, {
            from:address
        });
        let result = await unioContract.methods.walletOfOwner(address).call()
    return result;
  };
  return run();
}
// 获取图片地址
export function tokenURI(token){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.tokenURI(token).call()
    return result;
  };
  return run();
}

export function transferHt(address,amout) {
    let run = async () => {
        let web3s = new Web3(ethereum);
       let mc= await web3s.eth.getBalance(address).then(res=>{
        return res/10**18
       })
       console.log('ht',mc,amout);   
    if(mc-amout>0){
        let amount = web3s.utils.toWei(amout+'' , 'ether');
        let result=await  web3s.eth.sendTransaction({
           from:address,
           to:get_mc,
           value:amount
       }).then(res=>{
           console.log("res",res);
           return res.transactionHash
       }).catch(err=>{ Toast(err.message)})
       console.log("result",result);
       return result
    }else{
        return false
    }
  };
  return run();
}








